<template>
  <div class="product-mini-carousel-item flex--row-start-start">
    <div class="product-mini-carousel-item__image-holder">
      <div class="product-mini-carousel-item__image-sizer">
        <bleach-image
          :key="product.displayTitle"
          v-if="getPackshot"
          class="product-mini-carousel-item__image"
          :src="getPackshot.original_secure_url"
          :default-size="[120, 120]"
          :alt="
            (getPackshot.metadata && getPackshot.metadata.alt) ||
              product.displayTitle + ' product image'
          "
        />
      </div>
    </div>
    <div class="product-mini-carousel-item__content">
      <div
        itemprop="name"
        class="product-mini-carousel-item__title text--w-demi text--left text--clr-black mb-8"
        :class="[mini ? 'text-zd-cart-upsell' : 'text-commerce-intro']"
      >
        {{ product.displayTitle }}
      </div>
      <meta itemprop="description" :content="product.description" />
      <div class="product-mini-carousel-item__button-holder">
        <EcomButton
          class="product-mini-carousel-item__cta"
          mini
          :price="product.price"
          @click="
            () =>
              ADD_TO_CART({
                items: [{ product, quantity: 1 }],
                passive: false,
                openCart: false
              })
          "
        >
          {{ UI_ELEMENT("cta_add_to_cart_mini") }}
        </EcomButton>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/utils.js"
import { mapActions, mapGetters } from "vuex"

export default {
  props: {
    product: Object,
    mini: Boolean
  },

  methods: {
    ...mapActions("cart", ["ADD_TO_CART"]),
    getPrice(price) {
      return formatCurrency(price)
    }
  },
  computed: {
    ...mapGetters("locale", ["formatPrice"]),

    getPackshot() {
      return (
        this.product &&
        ((this.product.packshot && this.product.packshot[0]) ||
          (this.product.images && this.product.images[0]))
      )
    }
  }
}
</script>

<style lang="scss">
.product-mini-carousel-item {
  height: 100%;
  background: $bleach-white;
  cursor: grab;

  &__image-holder {
    position: relative;
    background: #e5e5e5;
    flex: 0 0 96px;
    width: 96px;
  }

  &__image-sizer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__content {
    flex: 1 1 auto;
    height: 100%;
    min-width: 0;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title {
    flex: 1 1 auto;
  }

  &__button-holder {
    flex: 0 0 auto;
    height: auto;
  }

  &__cta {
    &.bp-basic-button {
      margin: 0;
    }
  }
}
</style>
