<template>
  <component
    :is="mini ? 'div' : 'section'"
    class="product-mini-carousel noselect text--clr-white"
    :class="{
      'generic-section generic-section--no-float text--center':
        GET_IS_ABOVE_BREAKPOINT('md') && !mini
    }"
  >
    <div :class="{ container: GET_IS_ABOVE_BREAKPOINT('md') && !mini }">
      <div
        class="product-mini-carousel__title text--uppercase mb-16"
        :class="[mini ? 'text-commerce-heading-3' : 'text-commerce-super']"
      >
        {{ title }}
      </div>
      <div
        v-if="getIsGrid"
        class="product-mini-carousel__grid-container container flex--row-center-stretch"
      >
        <template v-for="product in products">
          <CarouselItem
            class="product-mini-carousel__grid-item"
            v-if="
              product &&
                product.quantityStatus !== quantityStatusTypes.OUT_OF_STOCK &&
                product.waitlist !== true
            "
            :product="product"
            :key="product.id"
            :mini="mini"
          />
        </template>
      </div>
      <BleachSwiper
        v-else
        :options="swiperOptions"
        class="product-mini-carousel__container"
        :key="currentWidth"
      >
        <template v-for="product in products">
          <BleachSwiperSlide
            class="product-mini-carousel__swiper-item swiper-slide"
            v-if="
              product &&
                product.quantityStatus !== quantityStatusTypes.OUT_OF_STOCK &&
                product.waitlist !== true
            "
            :key="product.id"
          >
            <CarouselItem :product="product" :mini="mini" />
          </BleachSwiperSlide>
        </template>
      </BleachSwiper>
    </div>
  </component>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import CarouselItem from "./ProductMiniCarouselItem.vue"

import { quantityStatusTypes } from "../store/constants"

import BleachSwiper from "@/components/swiper/BleachSwiper.vue"
import BleachSwiperSlide from "@/components/swiper/BleachSwiperSlide.vue"

export default {
  props: {
    title: String,
    products: Array,
    mini: Boolean
  },

  data() {
    return {
      quantityStatusTypes,
      swiperOptions: {
        spaceBetween: 20,
        slidesOffsetBefore: 24,
        slidesOffsetAfter: 24,
        slidesPerView: "auto",
        centeredSlides: false,
        mousewheel: true,
        breakpoints: {
          768: {
            slidesOffsetBefore: 40,
            slidesOffsetAfter: 40
          }
        }
      }
    }
  },

  components: {
    CarouselItem,
    BleachSwiper,
    BleachSwiperSlide
  },

  computed: {
    ...mapState("breakpoints", ["currentWidth"]),
    ...mapGetters("breakpoints", ["GET_IS_ABOVE_BREAKPOINT"]),

    getIsGrid() {
      if (this.mini) return false
      // if the number of items exceeds the limit for a given breakpoint, use the carousel
      const minForCarousel = {
        lg: 5,
        md: 4
      }

      for (const bp of Object.keys(minForCarousel)) {
        if (
          this.GET_IS_ABOVE_BREAKPOINT(bp) &&
          this.products.length < minForCarousel[bp]
        )
          return true
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.product-mini-carousel {
  background: getcolour(bleach_black);
  margin-top: 0;
  width: 100%;
  position: relative;
  padding-top: 24px;
  padding-bottom: 32px;
  overflow: hidden;

  &__title {
    display: block;
    margin-left: 24px;
    margin-right: 30px;
    border-bottom: 1px solid black;

    @include media-breakpoint-up(md) {
      margin-left: 40px;
    }
  }

  &__grid-container {
    column-gap: 12px;
    align-items: stretch;
  }

  &__grid-item {
    flex: 0 0 33.33%;
    width: 33.33%;
    height: auto;

    @include media-breakpoint-up(lg) {
      flex: 0 0 25%;
      width: 25%;
    }
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    width: 260px;
    height: auto;
  }
}
</style>
