var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.mini ? 'div' : 'section',{tag:"component",staticClass:"product-mini-carousel noselect text--clr-white",class:{
    'generic-section generic-section--no-float text--center':
      _vm.GET_IS_ABOVE_BREAKPOINT('md') && !_vm.mini
  }},[_c('div',{class:{ container: _vm.GET_IS_ABOVE_BREAKPOINT('md') && !_vm.mini }},[_c('div',{staticClass:"product-mini-carousel__title text--uppercase mb-16",class:[_vm.mini ? 'text-commerce-heading-3' : 'text-commerce-super']},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.getIsGrid)?_c('div',{staticClass:"product-mini-carousel__grid-container container flex--row-center-stretch"},[_vm._l((_vm.products),function(product){return [(
            product &&
              product.quantityStatus !== _vm.quantityStatusTypes.OUT_OF_STOCK &&
              product.waitlist !== true
          )?_c('CarouselItem',{key:product.id,staticClass:"product-mini-carousel__grid-item",attrs:{"product":product,"mini":_vm.mini}}):_vm._e()]})],2):_c('BleachSwiper',{key:_vm.currentWidth,staticClass:"product-mini-carousel__container",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.products),function(product){return [(
            product &&
              product.quantityStatus !== _vm.quantityStatusTypes.OUT_OF_STOCK &&
              product.waitlist !== true
          )?_c('BleachSwiperSlide',{key:product.id,staticClass:"product-mini-carousel__swiper-item swiper-slide"},[_c('CarouselItem',{attrs:{"product":product,"mini":_vm.mini}})],1):_vm._e()]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }